/*
 * @Author: LiZhiWei
 * @Date: 2024-05-08 15:23:08
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-05-11 11:17:56
 * @Description:
 */
import { MembershipApplication } from '~/models/application'
import { postMembershipApplication } from '~/api/application/application'
// import type { BasicResponse } from '~/models/common'

export const useMembershipApplicationStore = defineStore('membershipApplication', () => {
  const isShowMembershipApplication = ref(true)
  const postMembershipApplicationInfo = (application: MembershipApplication) => {
    // 提交入会申请
    return new Promise((resolve, reject) => {
      postMembershipApplication(application)
        .then((res) => {
          resolve(res.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  return {
    isShowMembershipApplication,
    postMembershipApplicationInfo,
  }
})
